import { useState } from 'react';
import { Switch } from 'antd';

import { usePutReviewMutation } from 'store';

import { IActiveSwitcher } from './types';

export const ActiveSwitcher = ({
  activeStatus,
  uid,
  disabled = false,
}: IActiveSwitcher) => {
  const [active, setActive] = useState(activeStatus);
  const [updateReview, { isLoading }] = usePutReviewMutation();

  const onChange = () => {
    if (uid) {
      updateReview({
        batch: true,
        data: [{ uid }],
        active: activeStatus ? 0 : 1,
      })
        .unwrap()
        .then(() => setActive((prev) => (prev === 0 ? 1 : 0)));
    }
  };

  return (
    <Switch
      loading={isLoading}
      disabled={disabled}
      checkedChildren="active"
      unCheckedChildren="unactive"
      checked={!!activeStatus}
      value={!!active}
      onChange={onChange}
    />
  );
};
