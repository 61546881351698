import { EPhotoSizes } from '../types';
import { API_CDN_URL } from 'envConfig';

const sizes = {
  origin: '/origin/',
  small: '/160x160/',
  middle: '/320x320/',
};

export const getPhoto = (name: string, type: EPhotoSizes = EPhotoSizes.ORIGIN): string => {
  return API_CDN_URL + sizes[type] + name;
};
