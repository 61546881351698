import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAdminGetDevicesResponse,
  IAdminGetDevicesRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

import { EDevicesTagType } from './types';

export const devicesApi = createApi({
  reducerPath: 'devicesApi',
  tagTypes: [EDevicesTagType.DEVICES],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getDevices: build.query<IAdminGetDevicesResponse, IAdminGetDevicesRequest>({
      query: (params) => ({
        url: `${version}/devices`,
        params,
      }),
      providesTags: (
        result
      ): { type: EDevicesTagType.DEVICES; id: string }[] =>
        result
          ? [
              ...result.devices.map(({ uid }) => ({
                type: EDevicesTagType.DEVICES,
                id: uid,
              })),
              { type: EDevicesTagType.DEVICES, id: 'List' },
            ]
          : [{ type: EDevicesTagType.DEVICES, id: 'List' }],
    }),
    deleteDevice: build.mutation<{ success: boolean }, string>({
      query: (uid) => ({
        url: `${version}/device`,
        method: 'DELETE',
        body: { uid },
      }),
      invalidatesTags: [{ type: EDevicesTagType.DEVICES, id: 'List' }],
    }),
  }),
});

export const { useGetDevicesQuery, useDeleteDeviceMutation } = devicesApi;
