import { Flex } from 'antd';

import { SCicrleColor } from './style';

const colorView = {
  '0': 'gold',
  '-1': 'red',
  '1': 'green',
};

export const CircleColor = ({ colorValue }: { colorValue: number }) => {
  const color = colorView[`${colorValue}` as keyof typeof colorView];

  return (
    <Flex justify="center">
      <SCicrleColor color={color} />
    </Flex>
  );
};
