import { useAuthenticator } from '@aws-amplify/ui-react';

import { appRoutesList } from 'router/routes/config';
import { TRouteChildren } from 'router/routes/types';

export const useUserRouter = () => {
  const { user } = useAuthenticator();

  if (!user.attributes?.['custom:role']) {
    return { getRouterChildren: undefined, getRoleRoutesList: undefined };
  }

  const getRoleRoutesList = () => {
    return appRoutesList.filter((route) =>
      route.role.includes(user.attributes?.['custom:role'] as string)
    );
  };

  const getRouterChildren = (): TRouteChildren => {
    return appRoutesList
      .filter((route) =>
        route.role.includes(user.attributes?.['custom:role'] as string)
      )
      .map((route) => {
        if (!route.index) {
          return {
            element: route.element,
            path: route.path,
            children: route.children,
          };
        }
        return { element: route.element, index: route.index };
      });
  };

  return {
    role: user.attributes?.['custom:role'],
    getRoleRoutesList,
    getRouterChildren,
  };
};
