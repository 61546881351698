import { Flex } from 'antd';

export const AuthHeader = () => {
  return (
    <Flex align="center" vertical>
      <h3>Welcome</h3>
      <div>to</div>
      <h2>Svitlofour Admin</h2>
    </Flex>
  );
};
