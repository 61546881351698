import { Amplify, Auth } from 'aws-amplify';
import { REGION, USER_POOL_ID, USER_POOL_WEB_CLIENT_ID } from './envConfig';

Amplify.configure({
  Auth: {
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  },
});

// You can get the current config object
const currentConfig = Auth.configure();

export default currentConfig;
