import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAdminGetReviewsResponse,
  IAdminGetReviewsRequest,
  IAdminPutReviewsResponse,
  IAdminPutReviewsRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';
import { EReviewsTagType } from './types';

export const reviewsApi = createApi({
  reducerPath: 'reviewsApi',
  tagTypes: [EReviewsTagType.REVIEWS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getReviews: build.query<IAdminGetReviewsResponse, IAdminGetReviewsRequest>({
      query: (params) => ({
        url: `${version}/reviews`,
        params,
      }),
      providesTags: (
        result
      ): { type: EReviewsTagType.REVIEWS; id: string }[] =>
        result
          ? [
              ...result.reviews.map(({ uid }) => ({
                type: EReviewsTagType.REVIEWS,
                id: uid,
              })),
              { type: EReviewsTagType.REVIEWS, id: 'List' },
            ]
          : [{ type: EReviewsTagType.REVIEWS, id: 'List' }],
    }),
    putReview: build.mutation<
      IAdminPutReviewsResponse,
      IAdminPutReviewsRequest
    >({
      query: (body) => ({
        url: `${version}/reviews`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: EReviewsTagType.REVIEWS, id: 'List' }],
    }),
  }),
});

export const { useGetReviewsQuery, usePutReviewMutation } = reviewsApi;
