import { ReactNode, Key } from 'react';

import { MenuItem } from './types';

export const getItem = (
  label: ReactNode,
  key?: Key | null,
  icon?: ReactNode,
  children?: MenuItem[]
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
};
