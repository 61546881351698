import { SorterResult } from 'antd/es/table/interface';
import { EDirection } from '@svitlofour/types';

export interface ISorterResult<RecordType> extends SorterResult<RecordType> {}

export const setTableSortOptions = <RecordType>(
  sorter: ISorterResult<RecordType> | ISorterResult<RecordType>[],
  setDirection: (directionVal: EDirection | undefined) => void
) => {
  if (!Array.isArray(sorter)) {
    setDirection(
      sorter.order === 'ascend'
        ? EDirection.ASC
        : sorter.order === 'descend'
          ? EDirection.DESC
          : undefined
    );
  }
};
