import { Tag } from 'antd';

const colors = [
  'geekblue',
  'green',
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'cyan',
  'blue',
  'purple',
];

export const CellHashTags = ({ tags }: { tags: string[] }) => {
  return (
    <>
      {tags.map((tag) => (
        <Tag
          color={colors[Math.floor(Math.random() * colors.length)]}
          key={tag}
          style={{ marginTop: '5px' }}
        >
          {tag}
        </Tag>
      ))}
    </>
  );
};
