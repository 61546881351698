export enum EPhotoSizes {
  ORIGIN = 'origin',
  SMALL = 'small',
  MIDDLE = 'middle',
}

export interface IPaginationPagesLimit {
  page: number;
  limit: number;
}
