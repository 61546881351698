import { useState, useEffect, Suspense } from 'react';
import { Button, Layout as AntdLayout } from 'antd';
import { Outlet, useNavigate, useLocation, NavLink } from 'react-router-dom';
import { Header, Layout as SvitlofourLayout, Footer } from '@svitlofour/ui';
import { useTranslation } from 'react-i18next';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@svitlofour/ui';

import { useUserRouter } from 'hooks';

import { useResetApiState } from './useResetApiState';
import { getItem } from './helpers';
import { MenuItem } from './types';

import { Logo } from '../components';

export const Layout = () => {
  const { t, i18n } = useTranslation();
  const { role, getRoleRoutesList } = useUserRouter();
  const { user, signOut } = useAuthenticator();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { resetApiState } = useResetApiState();

  const submitSignOut = () => {
    signOut();
    navigate('/');
    resetApiState();
  };

  useEffect(() => {
    if (getRoleRoutesList) {
      setMenuItems(
        getRoleRoutesList().map((item) =>
          getItem(<NavLink to={item.path}>{t(item.title)}</NavLink>, item.path)
        )
      );
    }
  }, [i18n.language]);

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <Header
        logo={<Logo />}
        button={
          <Button ghost type="primary" onClick={submitSignOut}>
            {t('buttons.logout')}
          </Button>
        }
        role={role as string}
        userName={user.attributes?.email}
      />

      <SvitlofourLayout pathname={pathname} items={menuItems}>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </SvitlofourLayout>

      <Footer />
    </AntdLayout>
  );
};
