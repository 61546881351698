import { Image } from 'antd';
import { Link } from 'react-router-dom';

import { SIconWrapper } from './style';

export const Logo = () => {
  return (
    <SIconWrapper align="center">
      <Link to="/">
        <Image
          width={50}
          preview={false}
          src="https://svitlofour.com/img/icon.svg"
          alt="logo"
        />
        <Image
          width={200}
          preview={false}
          src="https://svitlofour.com/img/svitloforName.svg"
          alt="logo-text"
        />
      </Link>
    </SIconWrapper>
  );
};
