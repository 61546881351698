import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { IFilterParameters } from './types';

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setFilterOnReviews(state, { payload }: { payload: IFilterParameters }) {
      state.isFilterOnReviews = payload;
    },
  },
});

export const { setFilterOnReviews } = reviewsSlice.actions;

export const reviewsReducer = reviewsSlice.reducer;
