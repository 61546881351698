import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import {
  reviewsApi,
  usersApi,
  devicesApi,
  complaintsApi,
  areasApi,
} from './queries';
import { rangePickerLocaleReducer, reviewsReducer } from './slices';

export const store = configureStore({
  reducer: {
    [reviewsApi.reducerPath]: reviewsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [devicesApi.reducerPath]: devicesApi.reducer,
    [complaintsApi.reducerPath]: complaintsApi.reducer,
    [areasApi.reducerPath]: areasApi.reducer,
    reviews: reviewsReducer,
    rangePickerLocale: rangePickerLocaleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(reviewsApi.middleware)
      .concat(usersApi.middleware)
      .concat(devicesApi.middleware)
      .concat(complaintsApi.middleware)
      .concat(areasApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
