import { IInitalState } from './types';

export const initialState: IInitalState = {
  isFilterOnReviews: {
    isDeletedReviews: undefined,
    isUserIdFilterOnReviews: undefined,
    isDeviceIdFilterOnReviews: undefined,
    colorValue: undefined,
    isReviewActive: undefined,
  },
};
