import { ReactElement, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { store } from 'store';
import { AuthHeader } from 'views/components';

import { Router } from 'router/Router';

const App = () => {
  return (
    <Provider store={store}>
      <Authenticator
        loginMechanisms={['email']}
        hideSignUp={true}
        components={{
          Header(): ReactElement {
            return <AuthHeader />;
          },
        }}
      >
        <Suspense>
          <Router />
        </Suspense>
      </Authenticator>
    </Provider>
  );
};

export default App;
