import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAdminGetUsersResponse,
  IAdminGetUsersRequest,
  ISuccessResponse,
  TUid,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

import { EUsersTagType } from './types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: [EUsersTagType.USERS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getUsers: build.query<IAdminGetUsersResponse, IAdminGetUsersRequest>({
      query: (params) => ({
        url: `${version}/users`,
        params,
      }),
      providesTags: (result): { type: EUsersTagType.USERS; id: string }[] =>
        result
          ? [
              ...result.users.map(({ uid }) => ({
                type: EUsersTagType.USERS,
                id: uid,
              })),
              { type: EUsersTagType.USERS, id: 'List' },
            ]
          : [{ type: EUsersTagType.USERS, id: 'List' }],
    }),
    deleteUser: build.mutation<ISuccessResponse, TUid>({
      query: (uid) => ({
        url: `${version}/user`,
        method: 'DELETE',
        body: { uid },
      }),
      invalidatesTags: [{ type: EUsersTagType.USERS, id: 'List' }],
    }),
  }),
});

export const { useGetUsersQuery, useDeleteUserMutation } = usersApi;
