import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query';
import { message } from 'antd';

import { API_SERVER_URL } from 'envConfig';

const baseQuery = fetchBaseQuery({
  baseUrl: API_SERVER_URL,

  prepareHeaders: async (headers) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    } catch (err) {
      message.error(`${err}`);
      setTimeout(async () => {
        await Auth.signOut();
        window.location.href = '/';
      }, 1000);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    try {
      await Auth.currentAuthenticatedUser();

      result = await baseQuery(args, api, extraOptions);
    } catch (err) {
      message.error(`${err}`);
      setTimeout(async () => {
        await Auth.signOut();
        window.location.href = '/';
      }, 1000);
    }
  }

  return result;
};
