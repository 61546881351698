import {
  useAppDispatch,
  reviewsApi,
  usersApi,
  devicesApi,
  areasApi,
  complaintsApi,
} from 'store';

export const useResetApiState = () => {
  const dispatch = useAppDispatch();

  const apiList = [reviewsApi, usersApi, devicesApi, areasApi, complaintsApi];

  const resetApiState = () => {
    apiList.forEach((api) => {
      dispatch(api.util.resetApiState());
    });
  };

  return { resetApiState };
};
