import { createSlice } from '@reduxjs/toolkit';
import enUSPicker from 'antd/es/date-picker/locale/en_US';
// import ukUAPicker from 'antd/es/date-picker/locale/uk';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';

interface IRangePickerLocaleInitialState {
  locale: PickerLocale;
}

const initialState: IRangePickerLocaleInitialState = {
  locale: enUSPicker,
};

export const rangePickerLocaleSlice = createSlice({
  name: 'rangePickerLocale',
  initialState,
  reducers: {
    setLocale(state, { payload }: { payload: IRangePickerLocaleInitialState }) {
      state.locale = payload.locale;
    },
  },
});

export const { setLocale } = rangePickerLocaleSlice.actions;

export const rangePickerLocaleReducer = rangePickerLocaleSlice.reducer;
