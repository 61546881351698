import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAdminGetComplaintsResponse,
  IAdminGetComplaintsRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const complaintsApi = createApi({
  reducerPath: 'complaintsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getComplaints: build.query<
      IAdminGetComplaintsResponse,
      IAdminGetComplaintsRequest
    >({
      query: (params) => ({
        url: `${version}/complaints`,
        params,
      }),
      // keepUnusedDataFor: 1, // example how manipulate with RTK Query cache(seconds). Default cache is 60 seconds.
      // cacheTime: 300 // example how to set cacheTime, in which will hold saved date.
    }),
  }),
});

export const { useGetComplaintsQuery } = complaintsApi;
