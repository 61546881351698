import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAdminGetAreasResponse,
  IAdminGetAreasRequest,
  IAdminPostAreaResponse,
  IAdminPostAreaRequest,
  IAdminPutAreaResponse,
  IAdminPutAreaRequest,
  IAdminDeleteAreaResponse,
  IAdminDeleteAreaRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

import { EAreasTagType } from './types';

export const areasApi = createApi({
  reducerPath: 'areasApi',
  tagTypes: [EAreasTagType.AREAS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getAreas: build.query<IAdminGetAreasResponse, IAdminGetAreasRequest>({
      query: (params) => ({
        url: `${version}/areas`,
        params,
      }),
      providesTags: (result): { type: EAreasTagType.AREAS; id: string }[] =>
        result
          ? [
              ...result.areas.map(({ uid }) => ({
                type: EAreasTagType.AREAS,
                id: uid,
              })),
              { type: EAreasTagType.AREAS, id: 'List' },
            ]
          : [{ type: EAreasTagType.AREAS, id: 'List' }],
    }),
    addArea: build.mutation<IAdminPostAreaResponse, IAdminPostAreaRequest>({
      query: (body) => ({
        url: `${version}/area`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: EAreasTagType.AREAS, id: 'List' }],
    }),
    putArea: build.mutation<IAdminPutAreaResponse, IAdminPutAreaRequest>({
      query: (body) => ({
        url: `${version}/area`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: EAreasTagType.AREAS, id: 'List' }],
    }),
    deleteArea: build.mutation<
      IAdminDeleteAreaResponse,
      IAdminDeleteAreaRequest
    >({
      query: (uid) => ({
        url: `${version}/area`,
        method: 'DELETE',
        body: { uid },
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: EAreasTagType.AREAS, id: 'List' }],
    }),
  }),
});

export const {
  useGetAreasQuery,
  useAddAreaMutation,
  usePutAreaMutation,
  useDeleteAreaMutation,
} = areasApi;
