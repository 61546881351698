import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { dateFormat } from 'shared';

export const useRangePickerDate = () => {
  const [date, setDate] = useState({
    fromDate: dayjs().startOf('year').format(dateFormat),
    toDate: dayjs().add(1, 'day').format(dateFormat),
  });

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setDate({
        fromDate: dateStrings[0],
        toDate: dayjs(dateStrings[1]).add(1, 'day').format(dateFormat),
      });
    } else {
      setDate({
        fromDate: dayjs().startOf('year').format(dateFormat),
        toDate: dayjs().add(1, 'day').format(dateFormat),
      });
    }
  };
  return { date, onRangeChange };
};
