import { Tooltip, Typography } from 'antd';
import { TUid } from '@svitlofour/types';

import { normalizeTextByLength } from 'shared';

export const CellWithTooltip = ({
  value,
  length = 8,
}: {
  value: TUid;
  length?: number;
}) => {
  return (
    <Tooltip
      title={<Typography.Text copyable>{value}</Typography.Text>}
      color="#d9d9d9"
      placement="top"
      overlayInnerStyle={{ color: '#000000' }}
    >
      <div>{normalizeTextByLength(length)(value)}</div>
    </Tooltip>
  );
};
