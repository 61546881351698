import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';

import './index.css';
import App from './App';
import './i18n';
import currentConfig from './aws-exports';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Amplify.configure(currentConfig);

root.render(<App />);
