import { ReactNode } from 'react';
import { LoaderFunction } from 'react-router-dom';

export type TIndex = true;

export type TRouteChildren = Array<IIndexRouteObject | INonIndexRouteObject>;

interface IRouteChildren {
  path: string;
  element: ReactNode;
}

export interface IRouteObject {
  role: string[];
  path: string;
  title: string;
  index?: TIndex;
  element: ReactNode;
  loader?: LoaderFunction;
  children?: IRouteChildren[];
}

export interface IIndexRouteObject {
  element: ReactNode;
  index: TIndex;
}

export interface INonIndexRouteObject {
  element: ReactNode;
  path: string;
}

export enum EAdmin {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superadmin',
  MODERATOR = 'moderator',
}
