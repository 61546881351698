import { lazy } from 'react';

const Dashboard = lazy(() => import('modules/dashboard'));
const Reviews = lazy(() => import('modules/reviews'));
const Users = lazy(() => import('modules/users'));
const Devices = lazy(() => import('modules/devices'));
const Photos = lazy(() => import('modules/photos'));
const Complaints = lazy(() => import('modules/complaints'));
const Comments = lazy(() => import('modules/comments'));
const Areas = lazy(() => import('modules/areas'));
const AreasTable = lazy(() => import('modules/areas/components/AreasTable'));
const CreateNewArea = lazy(
  () => import('modules/areas/components/CreateNewArea')
);

import { IRouteObject, EAdmin, TIndex } from './types';

import {
  DashboardPath,
  ReviewsPath,
  PhotosPath,
  UsersPath,
  DevicesPath,
  ComplaitnsPath,
  CommentsPath,
  AreasPath,
  CreateNewAreaPath,
} from './constants';

export const appRoutesList: IRouteObject[] = [
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN, EAdmin.MODERATOR],
    title: 'sidebar.dashboard',
    path: DashboardPath,
    index: true as TIndex,
    element: <Dashboard />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN, EAdmin.MODERATOR],
    title: 'sidebar.reviews',
    path: ReviewsPath,
    element: <Reviews />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN, EAdmin.MODERATOR],
    title: 'sidebar.photos',
    path: PhotosPath,
    element: <Photos />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN],
    title: 'sidebar.users',
    path: UsersPath,
    element: <Users />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN],
    title: 'sidebar.devices',
    path: DevicesPath,
    element: <Devices />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN, EAdmin.MODERATOR],
    title: 'sidebar.complaints',
    path: ComplaitnsPath,
    element: <Complaints />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN],
    title: 'sidebar.comments',
    path: CommentsPath,
    element: <Comments />,
  },
  {
    role: [EAdmin.ADMIN, EAdmin.SUPER_ADMIN],
    title: 'sidebar.areas',
    path: AreasPath,
    element: <Areas />,
    children: [
      {
        path: AreasPath,
        element: <AreasTable />,
      },
      {
        path: CreateNewAreaPath,
        element: <CreateNewArea />,
      },
    ],
  },
];
